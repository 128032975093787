.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #172523;
  color: white;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-container img {
  height: 40px;
  max-height: 40px; /* Prevent logo from becoming smaller */
    transition: height 0.3s; /* Smooth transition for resizing */
}
  
.navbar {
  display: none;
  list-style: none;
  margin-right: -70%;
  padding: 0;
  text-align:center; /* Center the menu items */
  font-family: 'Schibsted Grotesk';
}

.navbar li {
  display: inline-block;
  margin-right: 1rem;
}

.navbar a {
  color: white;
  text-decoration: none;
}


.hamburger-menu {
  display: block;
  cursor: pointer;
  margin-right: 2%;
}

.hamburger-menu img{
  height: 20px
}
  
