.table-container {
    width: 40%;
    margin: 20px auto;
    border-collapse: collapse;
    overflow-x: auto; /* Enable horizontal scrolling if needed */
  }
  
  table {
    border-radius: 15px;
    border: 1px solid #ccc; /* Set the border to a single shade */
    width: 100%;
    background-color:#172523;
    color:white ;
  }
  
  th, td {
    padding: 10px;
    text-align: left;
  }
   
  .see-btn, .delete-btn {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .delete-btn {
    background-color: #f44336;
  }