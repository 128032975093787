.button-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 1%; 
    margin-bottom: 2%;
    gap: 10px;
    
  }
  
  .share-button, .upload-button {
    padding: 8px 20px;
    background-color: #172523;
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    cursor: pointer;
    flex: 1 1 auto ;
    max-width: 45%;
  }

  .share-button img{
    height: 80%;
    width: 90%;
  }
  .upload-button img{
    height: 80%;
    width: 90%;
  }


  @media only screen and (max-width: 600px) {
    .share-button, .upload-button {
        max-width: 100%; /* Allow buttons to take full width */
        padding: 6px 15px; /* Smaller padding */
        font-size: 14px; /* Reduce font size */
    }

    .share-button img,
    .upload-button img {
        height: 50%; /* Reduce image height */
        width: 60%; /* Reduce image width */
    }
}

/* For very small mobile screens (max-width: 400px) */
@media only screen and (max-width: 400px) {
    .share-button, .upload-button {
        max-width: 100%; /* Full width for tiny screens */
        padding: 5px 12px; /* Smaller padding for tiny screens */
        font-size: 12px; /* Further reduce font size */
    }

    .share-button img,
    .upload-button img {
        height: 40%; /* Further reduce image size */
        width: 50%;
    }
}