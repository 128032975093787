.body-section {
    text-align: center;
    padding: 1rem;
    color:white;
    height: 85vh;
  }
  
.heading{
    display: flex;
    justify-content: center;
    font-size: 50px;
    font-family: 'Reddit Mono', sans-serif;
}  
.h-1{
    color: white;
}  
.h-2{
    color: #5CFF3F;
}
.infotxt{
    margin-top: -5rem;
    width: auto;
    justify-content: center;
    font-size: 20px;
    padding:2rem;
    line-height: 0.5;
    font-family: 'Schibsted Grotesk';

}

.open{
    justify-content: center;
    text-decoration: underline;
    text-underline-offset: 10px;
    text-decoration-color:#7E6B66 ;
    font-family: 'Schibsted Grotesk';
    margin-top: -10px;
}

.input-box{
    display: flex;
    justify-content: center;
    gap:150px;
    font-family: 'Schibsted Grotesk';
}

.input-container {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
}

.lable-name{
    margin-left: -50%;
    font-size: 25px;
    font-weight: 300;
}

.field-box{
    align-items: baseline;
}

input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 15px;
    height: 20px;
    width: 200px;
    background-color: #172523;
    color: white;
}

button {
    padding: 0.5rem 1rem;
    color: white;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    align-items: baseline;
    margin-top: 10px;
}


  .public-next-btn {
    background-color: #5CFF3F;
    width: 50px;
    height: 40px;
    border-radius: 15px;
  }
  .public-next-btn img{
    width: 20px;
    height: 15px;
  }
  .private-next-btn {
    background-color: white;
    width: 50px;
    height: 40px;
    border-radius: 15px;
  }
  .private-next-btn img{
    width: 20px;
    height: 15px;
  }


/*second white*/
.second-white{
    height: 500px;
    width: 100vw;
    display: flex;
    background-color: white;
}  

.img-section{
width: 40%;
height: 100%;
background-color: red;  /*img*/
}

.text-content{
width: 60%;
height: 100%;
align-items: center;
justify-content: center;
}

#h1-1{
    font-size: 40px;
    font-family: 'Reddit Mono';
    align-items: center;
    text-align: left;
    margin-left: 10%;
    margin-top: 15%;
}
#c1-1{
    font-size: 20px;
    align-items: center;
    justify-content: center;
    text-align: left;
    margin-left: 10%;
    margin-right: 20%;
    font-family: 'Schibsted Grotesk';
    margin-top: 5%;
}


/*third section*/
.third-dark{
    min-height: 75vh;
    width: 100vw;
    color: white;
}   

.third-lable{
    margin-left: 8%;
    font-family: 'Reddit Mono';
    margin-top: 5%;
    font-size: 24px;
}

.third-content{
    display: flex;
}

.third-img-section{
    width: 40%;
    height: 600px; /*check %*/
    background-color: red; /*img*/
}

.third-text-section{
    width: 60%;
    height: 500px;
}

#solution-text1{
    margin-top: 8%;
    margin-left: 5%;
    margin-right: 15%;
    text-align: left;
    font-family: 'Schibsted Grotesk';
}

.solution-text2{
    font-family: 'Reddit Mono';
    margin-top: 8%;
    margin-left: 5%;
    font-weight: normal;
    font-size: 52px;
    line-height: 0.5;
}





/*four section*/

.four-white{
    height: 70vh;
    width: 100vw;
    background-color: white;
}  

.four-lable{
    margin-left: 8%;
    padding-top: 8%;    
    font-family: 'Reddit Mono';
}
.four-content{
    display: flex;

}

.four-text-section{
    width: 60%;
    height: 200px;
}
#text-4{
    margin-top: 8%; 
    margin-left: 15%;
    margin-right: 5%;
    justify-content: center;
    font-family: 'Schibsted Grotesk';
    font-size: 18px;
}

.four-btn-section{
    width: 40%;
    height: 200px;
}

.four-btn{
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 30px; 
    margin-top: 15%;
    margin-left: 10%;
    padding: 15px 30px; /* Adjust the padding to control the spacing */
    cursor: pointer;
    font-size: 18px;
    color: black;
    border-color: black;
    font-family: 'Schibsted Grotesk';
}

/*five-dark*/

.five-dark{
    min-height: 400px;
    width: 100vw;
    color: white;
    font-family: 'Reddit Mono';
    font-size: 40px;
    font-weight: 700;
    line-height: 1;
}

.five-text-content{
    margin-top: 10%;
    margin-left: 10%;
}

.five-text1{
    color:#5CFF3F;
}

/*six white*/
.six-white{
    background-color: white;
    width: 100vw;
    height: 600px;
    color: black;
}

.six-top{
    display: flex;
}

.six-left{
    width: 40%;
    height: 300px;
    font-family: 'Schibsted Grotesk';
}

#six-text-1{
    margin-left: 15%;
    margin-top: 8%;
    font-size: 18px;
}
#six-text-2{
    margin-left: 15%;
    margin-top: 5%;
    font-size: 18px;
    font-weight: bold;
}

.six-left-field{
    display: flex;
    margin-top: 5%;
    margin-left: 15%;
}

.six-input{
width: 60%;
height: 8%;
  padding: 0.75rem 1rem;
  border: 1px solid black;
  border-radius: 10px;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  background-color: white;
  color: black;

}

.six-btn{
    width: 20%;
    height: 40px;
    background-color: black;
    color: white;
    border-radius: 10px;
    align-items: baseline;
    margin-top: -0px;
}

#six-text-3{
    margin-left: 15%;
    width: 400px;
    font-size: 10px;
    font-weight: lighter;
    color: #3b4043;
}

.six-right{
    width: 60%;
    height: 300px;
    display: flex;
    justify-content: space-between; /* Distribute space between the items */
  padding: 1rem;
}

.six-right-text {
    flex: 1; /* Make each section take equal width */
    margin: 0 1rem; /* Add margin to create space between them */
    margin-left: 10%;
    margin-top: 3%;
  }

.six-right-heading {
    font-size: 1.5rem;
    margin-bottom: 0.5rem; 
    font-family: 'Reddit Mono';
    font-size: 20px;
}
.six-right-content{
    font-family: 'Schibsted Grotesk';
    font-size: 18px;
    font-weight: 300;
    color: #646464;
}

.six-bottom{
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 100px;
    margin-top: -10%;
}
.six-bottom-left{
    font-family: 'Montserrat';
    font-weight: normal;
}

.six-bottom-right{
    font-family: 'Roboto Slab';
}

