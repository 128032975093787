.homepage{
    background-color: #172523;
    width: 98vw;
}

.homepage {
    height: 97vh;
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Hide horizontal scrollbar */
    scrollbar-width: none; /* Hide scrollbar on Chrome, Safari, and Opera */
    -ms-overflow-style: none; /* Hide scrollbar on Internet Explorer */
  } 