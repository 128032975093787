.main-div{
    background-color: #172523;
}

.container{
    background-color: #172523;
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
}
  
  .file-menu {
    width: 30%;
    max-height: 500px;
    max-width: 200px;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 25%;
    padding: 20px;
    background-color: #172523;
    border-radius: 10px;
    border: 1px solid #ffffff;
  }
  

  .text-editor-container {
    flex: 1; /* Allow the textarea to expand to fill available space */

    width: 65vw;
  }
  
  .text-editor {
    width: 100%; /* Ensure textarea takes up full width of its container */
    height: 100%;
    /* ... other styles ... */
  }
