.footer {
  padding: 1rem;
  background-color: white;
  display: flex;
  flex-direction: column; /* Stack items vertically on small screens */
  align-items: center; /* Center items on small screens */
  justify-content: center; /* Center items */
}

.footer-text {
  margin-left: 0; /* Reset left margin for better alignment on mobile */
  margin-bottom: 1rem; /* Add some space below the text */
  text-align: center; /* Center the footer text */
}

.footer-links {
  display: flex;
  flex-wrap: wrap; /* Allow links to wrap to the next line if needed */
  gap: 1.5rem;
  justify-content: center; /* Center links in the footer */
  margin-right: 0; /* Reset right margin for better alignment */
}

.footer-links a {
  color: black;
  text-decoration: none;
}

/* For larger screens (min-width: 601px) */
@media only screen and (min-width: 601px) {
  .footer {
      flex-direction: row; /* Stack items horizontally on larger screens */
      justify-content: space-between; /* Space between items */
  }

  .footer-text {
      margin-left: 3%; /* Left margin for larger screens */
      margin-bottom: 0; /* Remove bottom margin on larger screens */
  }

  .footer-links {
      margin-right: 20px; /* Keep right margin for larger screens */
  }
}