.second-page {
    height: 87vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #172523;
    overflow: hidden;
  }
  
  .text-editor-container {
    border: 1px solid #3e3d3d;
    flex: 1;
    width: 80%;
    max-width: 1000px;
    background-color: #222;
    padding: 20px;
    border-radius: 10px;
  }
  
  .text-editor {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    color: white;
    background-color: #222;
    resize: none;
  }


  ::-webkit-scrollbar {
    width: 10px;
    background-color: #333; 
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border: 2px solid #333; 
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-track-piece:hover {
    background-color: #444; 
    
  }

