.file-menu {
    width: 30%;
    max-width: 200px;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5%;
    padding: 20px;
    background-color: #172523;
    border-radius: 10px;
    border: 1px solid #ffffff;
  }
  
  .file-menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    color: #5CFF3F;
    font-size: 18px;
  }
  
  .create-folder-btn, .create-file-btn {
    background-color: transparent;
    border: 1px solid #5CFF3F;
    color: #5CFF3F;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 10px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .create-folder-btn:hover, .create-file-btn:hover {
    background-color: #5CFF3F;
    color: #172523;
  }
  
  ul.file-list {
    list-style: none;
    padding: 0;
    margin: 0;
    color: #ffffff;
  }
  
  .folder-item {
    margin-bottom: 20px;
  }
  .folder-item img {
    margin-right: 10px; /* Adjust the space between the folder icon and folder name */
    height: 24px;
  }
  .folder-name {
    margin-left: 10px; /* Add space between the folder name and the folder icon */
  }

  .folder-name {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .folder-name span {
    margin-left: 10px;
    color: #ffffff;
  }
  
  .file-list-nested {
    list-style: none;
    padding-left: 25px; /* Add spacing between folder and files */
    margin-top: 5px;
  }
  
  .file-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .file-item span {
    margin-left: 10px;
    color: #ffffff;
  }
  
  .file-list-nested .create-file-btn-nested {
    margin-top: 10px;
  }
  
  .create-file-btn-nested .create-file-btn {
    background-color: transparent;
    border: none;
    color: #5CFF3F;
    font-size: 14px;
    cursor: pointer;
  }
  
  .create-file-btn-nested .create-file-btn:hover {
    color: #ffffff;
  }
  
  .general-files-title {
    color: #ffffff;
    margin-bottom: 10px; /* Remove extra top margin */
    font-size: 18px;
    margin-left: 10px; /* Align with folder names */
  }
  
  .general-files {
    padding-left: 25px; /* Align with folder contents */
    margin: 0; /* Remove extra margin */
  }
  
  .general-files .file-item {
    margin-bottom: 5px;
  }
  
  .general-files .file-item span {
    margin-left: 10px;
    color: #ffffff;
  }
  
  @media (max-width: 768px) {
    .file-menu {
      width: 100%;
      padding: 10px;
    }
  
    .file-menu-header {
      font-size: 16px;
    }
  
    .create-folder-btn, .create-file-btn {
      font-size: 14px;
    }
  
    span {
      font-size: 12px;
    }
  }